.Create {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-content: center;
    width: auto ;
    align-self: flex-end;
    align-items: center;
}
.CreateInput {
    size: auto;
    border: 1px solid wheat;
}