.page-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.page-footer {
    margin-top: auto;
}

.page-footer-hidden {
    margin-bottom: auto;
    visibility: hidden;
}