.name {
    font-size: 38px;
    font-weight: 700;
    line-height: 100%;
}

.description {
    font-size: 18px;
}

.countdown {
    font-size: 44px;
    line-height: 100%;
}

.date {
    font-size: 28px;
    font-weight: 700;
    line-height: 100%;
}