.footer {
    /* display: flex;
    flex-direction: row;
    justify-content: space-between; */
    color: white;
    display: flex;
    /* flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: stretch;
    align-items: baseline;
    width: 90vw; */
}

a:link, a:visited, a:hover, a:active {
    color: white;
}

i {
    color: white
}