@import url(https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap);
body {
  background-color: #3DA5D9 !important;
  /* https://coolors.co/283d3b-3da5d9-edddd4-c44536-772e25 */
  font-family: 'Work Sans', sans-serif !important;
}

.App {
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* Small devices (landscape phones, 576px and up)*/

@media (min-width: 576px) {
  .w-sm-100 {
    width: 100%!important;
  }
  .w-sm-90 {
    width: 90%!important;
  }
  .w-sm-75 {
    width: 75%!important;
  }
  .w-sm-50 {
    width: 50%!important;
  }
  .w-sm-25 {
    width: 25%!important;
  }
  .h-sm-100 {
    height: 100%!important;
  }
  .h-sm-75 {
    height: 75%!important;
  }
  .h-sm-50 {
    height: 50%!important;
  }
  .h-sm-25 {
    height: 25%!important;
  }
}

/* Medium devices (tablets, 768px and up)*/

@media (min-width: 768px) {
  .w-md-100 {
    width: 100%!important;
  }
  .w-md-75 {
    width: 75%!important;
  }
  .w-md-50 {
    width: 50%!important;
  }
  .w-md-25 {
    width: 25%!important;
  }
  .h-md-100 {
    height: 100%!important;
  }
  .h-md-75 {
    height: 75%!important;
  }
  .h-md-50 {
    height: 50%!important;
  }
  .h-md-25 {
    height: 25%!important;
  }
}

/* Large devices (desktops, 992px and up)*/

@media (min-width: 992px) {
  .w-lg-100 {
    width: 100%!important;
  }
  .w-lg-75 {
    width: 75%!important;
  }
  .w-lg-50 {
    width: 50%!important;
  }
  .w-lg-25 {
    width: 25%!important;
  }
  .h-lg-100 {
    height: 100%!important;
  }
  .h-lg-75 {
    height: 75%!important;
  }
  .h-lg-50 {
    height: 50%!important;
  }
  .h-lg-25 {
    height: 25%!important;
  }
}

/* Extra large devices (large desktops, 1200px and up)*/

@media (min-width: 1200px) {
  .w-xl-100 {
    width: 100%!important;
  }
  .w-xl-75 {
    width: 75%!important;
  }
  .w-xl-50 {
    width: 50%!important;
  }
  .w-xl-25 {
    width: 25%!important;
  }
  .h-xl-100 {
    height: 100%!important;
  }
  .h-xl-75 {
    height: 75%!important;
  }
  .h-xl-50 {
    height: 50%!important;
  }
  .h-xl-25 {
    height: 25%!important;
  }
}
.footer {
    /* display: flex;
    flex-direction: row;
    justify-content: space-between; */
    color: white;
    display: flex;
    /* flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: stretch;
    align-items: baseline;
    width: 90vw; */
}

a:link, a:visited, a:hover, a:active {
    color: white;
}

i {
    color: white
}
.page-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.page-footer {
    margin-top: auto;
}

.page-footer-hidden {
    margin-bottom: auto;
    visibility: hidden;
}
.name {
    font-size: 38px;
    font-weight: 700;
    line-height: 100%;
}

.description {
    font-size: 18px;
}

.countdown {
    font-size: 44px;
    line-height: 100%;
}

.date {
    font-size: 28px;
    font-weight: 700;
    line-height: 100%;
}
.Create {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-content: center;
    width: auto ;
    align-self: flex-end;
    align-items: center;
}
.CreateInput {
    size: auto;
    border: 1px solid wheat;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

